import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueWow from 'vue-wow'
import VueI18n from 'vue-i18n'
import vClickOutside from 'v-click-outside'

import VAnimate from '@/components/global/VAnimate'
import VDropdown from '@/components/global/VDropdown'
import VSpinner from '@/components/global/VSpinner'

import messages from '@/i18n/index.js'

Vue.use(VueWow)
Vue.use(VueI18n)
Vue.use(vClickOutside)

Vue.component('VAnimate', VAnimate)
Vue.component('VDropdown', VDropdown)
Vue.component('VSpinner', VSpinner)

Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: 'uk',
  messages
})

const vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
