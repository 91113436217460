import { render, staticRenderFns } from "./IntroScreen.vue?vue&type=template&id=f6648ff2&scoped=true&"
import script from "./IntroScreen.vue?vue&type=script&lang=js&"
export * from "./IntroScreen.vue?vue&type=script&lang=js&"
import style0 from "./IntroScreen.vue?vue&type=style&index=0&id=f6648ff2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6648ff2",
  null
  
)

export default component.exports