export default {
  base: {
    dear_our: 'Cari nostri',
    you: 'вас',
    caption: 'Vi invitiamo a condividere<br> la gioia della nostra unione',
    december: 'dicembre',
    sunday: 'Domenica',
    caption_2: 'Saremo lieti di vedervi<br> ai nostri matrimoni<br> e banchetti nuziali.',
    ceremony: 'Matrimonio',
    banquet: 'Bankiet',
    look_at_map: 'Dai un\'occhiata alla mappa',
    yes: 'Si',
    no: 'No',
    and: 'e',
    join: 'aderire'
  },
  menu: {
    item_1: 'casa',
    item_2: 'la nostra foto',
    item_3: 'codice di abbigliamento',
    item_4: 'telegram'
  },
  info: {
    address_1: 'Vinnytsia, strada Gromova, 43<br> "Chiesa della Salvezza"<br> Inizio alle 12:00',
    address_2: 'Vinnytsia, via Maksymovycha, 5<br> sala banchetti della chiesa<br> "Casa del Vangelo"<br> Inizio alle 14:30'
  },
  texts: {
    intro_title: 'l\'Eterno disse:<br> "...E darò loro uno stesso<br> cuore e una stessa via..."',
    intro_subtitle: 'Jeremiah 32:39',
    no_invitation_id: 'per aprire l\'invito<br> Usa il tuo<br> link unico :)'
  },
  presents: {
    text_1: 'In relazione al nostro trasloco<br> saremo grati per regali<br> non grandi)',
    text_2: 'Per vostra comodità,<br> se desidera, inviare desideri<br> si puo pure qui:',
    make_present: 'Fare un regalo',
    bank: 'Revolut',
    number: '4350 4470 4351 8340'
  },
  dress: {
    text_1: 'Vi saremo grati se sosterrate<br> il colore del nostro matrimonio<br> con il dress-cod:'
  },
  rsvp: {
    text_1: 'Riuscirai ad unirti?<br> festeggiare con noi?',
    text_2: 'In quali parti della vacanza hai<br> intenzione di essere presente?',
    text_3: 'Si prega di ritirare la conferma<br> di partecipazione fino<br> al 25 novembre.'
  },
  telegram: {
    text_1: 'Gruppo in Telegram<br> per i nostri ospiti:'
  },
  timing: {
    title: 'Programma del<br> giorno del matrimonio',
    text_1: '* Sono previsti saluti e foto con gli ospiti<br> subito dopo il matrimonio, quindi aspettiamo tutti<br> alle 12:00.',
    item_1: 'matrimonio',
    item_2: 'saluti, servizio fotografico con gli ospiti *',
    item_3: 'trasferimento al banchetto',
    item_4: 'I parte del banchetto',
    item_5: 'pausa',
    item_6: 'II parte del banchetto',
    item_7: 'taglio della torta nuziale',
    item_8: 'fine'
  },
  photos: {
    title: 'Ecco il nostro<br> foto e video del matrimonio'
  }
}
