<template lang="pug">
  .v-dropdown(
    ref="dropdown"
    v-click-outside="closeDropdown")
    .v-dropdown__toggler(
      @click="toggle")
      slot(:toggled="toggled")
    transition(name="dropdown")
      .v-dropdown__content(
        v-if="toggled"
        :class="classes"
        @click="onContentClick")
        slot(name="content")
</template>

<script>
export default {
  name: 'VDropdown',

  props: {
    autoClose: Boolean,
    disabled: Boolean,
    widthAuto: Boolean,
    center: Boolean,
    positionRight: Boolean,
    fixedBottom: Boolean
  },

  data: () => ({
    toggled: false
  }),

  methods: {
    toggle () {
      if (!this.disabled) {
        this.toggled = !this.toggled
        this.$emit('toggle', this.toggled)
      }
    },

    onContentClick () {
      if (this.autoClose) {
        this.closeDropdown()
      }
    },

    closeDropdown () {
      this.toggled = false
      this.$emit('toggle', this.toggled)
    }
  },

  computed: {
    classes () {
      return {
        'v-dropdown__content--center': this.center,
        'v-dropdown__content--width-auto': this.widthAuto,
        'v-dropdown__content--position-right': this.positionRight,
        'v-dropdown__content--fixed-bottom': this.fixedBottom
      }
    }
  }
}
</script>

<style lang="scss">
  .v-dropdown {
    position: relative;

    &__toggler {
      position: relative;
      cursor: pointer;
      height: 100%;
    }
  }

  .v-dropdown__content {
    position: absolute;
    width: 100%;
    z-index: 101;
    left: 0;

    &--center > * {
      transform: translateX(-50%);
    }

    &--width-auto {
      width: auto;
    }

    &--position-right {
      right: 0;
      left: auto;
    }

    &--fixed-bottom {
      position: fixed;
      left: 0rem;
      right: 0rem;
      bottom: 0rem;
    }
  }

  .dropdown-enter-active,
  .dropdown-leave-active {
    transition: all .3s;
  }

  .dropdown-enter,
  .dropdown-leave-to {
    opacity: 0;
    transform: translateY(10px);
  }
</style>
