export default {
  base: {
    dear: 'Drogi',
    dear_boy: 'Drogiy',
    dear_girl: 'Droga',
    you_2: 'was',
    dear_our: 'Drogi nasze',
    you: 'was',
    caption: 'Zapraszamy {you} rozdzielić radość naszego ślubu',
    december: 'grudnia',
    sunday: 'niedziela',
    caption_2: 'Z przyjemnością spotkamy<br> się na naszym weselu<br> i bankiecie weselnym.',
    ceremony: 'Ślub',
    banquet: 'Bankiet',
    look_at_map: 'Spójrz na mapę',
    yes: 'tak',
    no: 'Nie',
    and: 'i',
    join: 'Przystąp'
  },
  menu: {
    item_1: 'Dom',
    item_2: 'nasze zdjęcia',
    item_3: 'dress code',
    item_4: 'telegram'
  },
  info: {
    address_1: 'Winnica, ulica  Gromova, 43<br> Kościół Zbawienia<br> Zacznij o 12:00',
    address_2: 'Winnica, ul. Maksymowicza, 5<br> sala bankietowa kościoła<br> „Dom Ewangelii”<br> Zacznij o 14:30'
  },
  texts: {
    intro_title: 'Bóg powiedział:<br> „I dam im jedno serce,<br> i wskażę jedną drogę,<br> aby się mnie bali po wszystkie<br> dni dla dobra ich samych<br> i ich dzieci po nich',
    intro_subtitle: 'Jeremiasza 32:39',
    no_invitation_id: 'otworzyć zaproszenie<br> Użyj swojego<br> unikalny link :)'
  },
  presents: {
    text_1: 'W związku z naszą przeprowadzką<br> będziemy wdzięczni za drobne upominki.<br> Przeprowadzka do Warszawy! 😉',
    text_2: 'Dla Twojej wygody możesz<br> wysłać pozdrowienia<br> bezpośrednio tutaj',
    make_present: 'Zrób prezent',
    bank: 'Revolut',
    number: '4350 4470 4351 8340'
  },
  dress: {
    text_1: 'Będziemy wdzięczni, jeśli<br> poprzecie kolor naszego wesela<br> dres-kodem:'
  },
  rsvp: {
    text_1: 'Czy będziesz mógł dołączyć<br> świętować z nami?',
    text_2: 'НW jakich częściach wakacji<br> planujesz być obecny?',
    text_3: 'Proszę potwierdzić<br> o twojej obecności<br> do 25 listopada.'
  },
  telegram: {
    text_1: 'Grupa w Telegramie<br> dla naszych gości:'
  },
  timing: {
    title: 'Harmonogram<br> dnia ślubu:',
    text_1: '* Pozdrowienia i zdjęcia z gośćmi zaplanowane<br> są zaraz po weselu, dlatego czekamy na wszystkich<br> o godzinie 12:00.',
    item_1: 'ślub',
    item_2: 'pozdrowienia, sesja zdjęciowa z gośćmi *',
    item_3: 'przejazd na bankiet',
    item_4: 'I część bankietu',
    item_5: 'przerwa',
    item_6: 'II część bankietu',
    item_7: 'krojenie tortu weselnego',
    item_8: 'koniec'
  },
  photos: {
    title: 'Tu będzie nasze<br> zdjęcia i filmy ślubne'
  }
}
