<template lang="pug">
  #app
    desktop-wrapper
      template(slot="noscroll")
        app-menu
        intro-screen(
          v-if="showIntro && hasInvitation"
          @intro="showIntro = false")
      app-header(v-if="hasInvitation")
      router-view
</template>

<script>
import { preloadImage } from '@/util/index.js'

import AppMenu from '@/components/common/AppMenu'
import AppHeader from '@/components/common/AppHeader'
import IntroScreen from '@/components/common/IntroScreen'
import DesktopWrapper from '@/components/common/DesktopWrapper'

export default {
  name: 'App',

  components: {
    AppMenu,
    AppHeader,
    IntroScreen,
    DesktopWrapper
  },

  computed: {
    hasInvitation () {
      return !!this.$store.getters.invitationId
    }
  },

  data: () => ({
    showIntro: true,
    isAppLoaded: false
  }),

  async mounted () {
    const intro = require('@/assets/img/splash-screen.jpg')
    await preloadImage(intro)
    this.isAppLoaded = true
    this.preloadResources()
  },

  methods: {
    async preloadResources () {
      await Promise.all([
        preloadImage(require('@/assets/img/main.jpg')),
        preloadImage(require('@/assets/svg/caption.svg')),
        preloadImage(require('@/assets/img/photos-bg.jpg')),
        preloadImage(require('@/assets/img/slider-01.jpg'))
      ])
    }
  }
}
</script>

<style lang="scss">
  @import '@/sass/main.scss';

  #app {
    position: relative;

    .app-inner {
      position: relative;
      max-width: 80rem;
      margin: 0 auto;
    }
  }
</style>
