export default {
  base: {
    dear: 'Дорогі',
    dear_boy: 'Дорогий',
    dear_girl: 'Дорога',
    you_2: 'вас',
    dear_our: 'Дорогі наші',
    you: 'вас',
    caption: 'запрошуємо {you} розділити<br> радість нашого одруження',
    december: 'грудень',
    sunday: 'неділя',
    caption_2: 'Раді будемо бачити Вас<br> на нашому вінчанні<br> та весільному банкеті.',
    ceremony: 'ВIНЧАННЯ',
    banquet: 'Банкет',
    look_at_map: 'подивитись на мапі',
    yes: 'Так',
    no: 'Ні',
    and: 'та',
    join: 'приєднатися'
  },
  menu: {
    item_1: 'головна',
    item_2: 'наші фото',
    item_3: 'дрес-код',
    item_4: 'телеграм'
  },
  info: {
    address_1: 'м.Вінниця, вул. Громова, 43<br> Церква "Спасіння"<br> Початок о 12:00',
    address_2: 'м.Вінниця, вул. Максимовича, 3а<br> Банкетна зала церкви<br> "Дім Євангелія"<br> Початок о 14:30'
  },
  texts: {
    intro_title: 'Бог сказав:<br> “І дам їм одне серце<br> та дорогу одну...”',
    intro_subtitle: 'Єремія 32:39',
    no_invitation_id: 'Щоб відкрити запрошення<br> скористайтесь своїм унікальним<br> посиланням =)'
  },
  presents: {
    text_1: 'У звязку з нашим переїздом,<br> будемо вдячні за невеликі по об\'єму<br> подарунки або ті, що поміщаються<br> у конверт 😉',
    text_2: 'Для вашої зручності,<br> можна відправити побажання<br> прямо тут :)',
    make_present: 'зробити подарунок',
    bank: 'MONOBANK',
    number: '5375 4115 0325 7557'
  },
  dress: {
    text_1: 'Нам буде дуже приємно, якщо ви<br> підтримаєте колір нашого весілля<br> дрес-кодом:'
  },
  rsvp: {
    text_1: 'Чи зможете ви приєднатись<br> до святкування разом з нами?',
    text_2: 'На яких частинах свята<br> плануєте бути присутніми?',
    text_3: 'Просимо надіслати підтвердження<br> про свою присутність<br> до 25 листопада.'
  },
  telegram: {
    text_1: 'Для швидкого обміну<br> інформацією, фото та відео<br> між нашими гостями<br> ми створили групу<br> в Telegram'
  },
  timing: {
    title: 'Розпорядок<br> весільного дня:',
    text_1: '* Привітання та фото з гостями заплановано<br> одразу після вінчання, тому чекаємо всіх<br> на 12:00.',
    item_1: 'Вінчання',
    item_2: 'Привітання, фото з гостями *',
    item_3: 'Переїзд на банкет',
    item_4: 'І частина банкету',
    item_5: 'Перерва',
    item_6: 'ІІ частина банкету',
    item_7: 'Розрізання весільного торта',
    item_8: 'Закінчення'
  },
  photos: {
    title: 'Тут будуть наші<br> весільні фото і відео'
  }
}
